import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { Button } from 'primereact/button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { withRouter } from 'react-router-dom';
import { PermissionsService, RolesService } from '../../remote-api/api/user-management-service';
import SidemenuDraggable from './sidemenu.draggable';
import { StatefulTargetBox as TargetBox } from './target.box';

const permissionsService = new PermissionsService();
const rolesService = new RolesService();

const useStyles = theme => ({
  root: {
    padding: 20,
  },
  accordianOuter: {
    padding: '0 15px',
    backgroundColor: '#fafafa !important',
  },
  AccordionSummary: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
  accList: {
    flexDirection: 'column',
    padding: '0 0 15px 0 !important',
  },
  accElm: {
    /* padding: '10px 0',
        cursor: 'pointer' */
  },
  headerSection: {
    justifyContent: 'space-between',
  },
  actionBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

/* const roleList = [
    { id: 1, value: 'Front End Developer' },
    { id: 2, value: 'Developer' },
    { id: 3, value: 'Manager' },
    { id: 4, value: 'Ast. Manager' },
    { id: 5, value: 'Sr. Developer' },
    { id: 6, value: 'Jr. Developer' }
]; */

/* const menuList = [
    { id: 'm1', value: 'HR Admin' },
    { id: 'm2', value: 'Dashboard' },
    { id: 'm3', value: 'User Details' },
    { id: 'm4', value: 'Organization Details' },
    { id: 'm5', value: 'Role Details' },
    { id: 'm6', value: 'Menu Details' }
]; */

/* const accessTypes = [
    { id: 1, name: 'create' },
    { id: 2, name: 'read' },
    { id: 3, name: 'update' },
    { id: 4, name: 'delete' },
]; */

class AccessRightsFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: 'panel2',
      rows: [{ roleName: '' }],
      permissions: {},
      menuList: [],
      roleList: [],
      accessTypes: [],
    };
  }

  componentDidMount() {
    permissionsService.getPermissions().subscribe(res => {
      const menuList = Object.keys(res).map((value, i) => ({
        id: `m${i + 1}`,
        value,
      }));

      const accessTypes = [];
      for (let i in res) {
        res[i].map(r => {
          const col = r.split('_')[0].trim();
          const isExist = accessTypes.some(c => c.name === col);
          if (!isExist) {
            accessTypes.push({ id: accessTypes.length + 1, name: col });
          }
        });
      }

      this.setState({
        ...this.state,
        permissions: res,
        menuList,
        accessTypes,
      });
    });

    if (this.props.match.params.roleName) {
      rolesService.getRoleDetails(this.props.match.params.roleName).subscribe(res => {
        let rl = [];

        for (let key in res.permission) {
          const values = res.permission[key];
          const accessList = values.map(v => v.split('_')[0]).reduce((a, v) => ({ ...a, [v]: true }), {});
          const accessCheckedList = this.state.accessTypes.filter(a =>
            this.state.permissions[key].some(p => p.indexOf(a.name) > -1),
          );
          const fullAccess = accessCheckedList.every(({ name }) => accessList[name]);
          rl.push({
            menuName: key,
            id: key,
            access: accessList,
            full: fullAccess,
          });
        }

        this.setState({
          ...this.state,
          rows: [{ roleName: res.name }, ...rl],
        });
      });
    }
  }

  onDrop = data => {
    if (data.type === 'role') {
      if (this.state.rows.length === 0) {
        this.state.rows.push({});
      }
      this.state.rows[0] = { roleName: data.name, id: data.id };
    } else {
      const isMenuExist = this.state.rows.some((m, i) => i > 0 && m.menuName === data.name);
      if (!isMenuExist) {
        this.state.rows.push({ menuName: data.name, id: data.id, access: {} });
      }
    }

    this.setState({
      ...this.state,
      rows: [...this.state.rows],
    });
  };

  toggleFullAccess = (e, idx, row) => {
    this.state.rows[idx].full = e.target.checked;
    const accessCheckedList = this.state.accessTypes.filter(a =>
      this.state.permissions[row.menuName].some(p => p.indexOf(a.name) > -1),
    );
    accessCheckedList.forEach(({ name }) => {
      this.state.rows[idx]['access'][name] = e.target.checked;
    });

    this.setState({
      ...this.state,
      rows: [...this.state.rows],
    });
  };

  toggleAccess = (e, idx, accessName, row) => {
    this.state.rows[idx]['access'][accessName] = e.target.checked;

    if (this.state.rows[idx].full && !e.target.checked) {
      this.state.rows[idx].full = false;
    }

    /**
     * If all fields `true` then full `true`.
     */
    if (!this.state.rows[idx].full) {
      const accessCheckedList = this.state.accessTypes.filter(a =>
        this.state.permissions[row.menuName].some(p => p.indexOf(a.name) > -1),
      );
      this.state.rows[idx].full = accessCheckedList.every(({ name }) => this.state.rows[idx]['access'][name]);
    }

    this.setState({
      ...this.state,
      rows: [...this.state.rows],
    });
  };

  handleAcordianChange = panel => {
    this.setState({
      ...this.state,
      expanded: panel,
    });
  };

  handleClose = () => {
    this.props.history.push('/user-management/access-rights?mode=viewList');
  };

  isAcessExist = (row, accessType) => {
    return this.state.permissions[row.menuName].some(o => o.indexOf(accessType.name) > -1);
  };

  handleRoleName = e => {
    this.state.rows[0].roleName = e.target.value;
    this.setState({
      ...this.state,
      rows: this.state.rows,
    });
  };

  createAccessRole = () => {
    const [role, ...menuList] = this.state.rows;
    let permission = {};
    menuList.map(menu => {
      permission[menu.menuName] = Object.keys(menu.access)
        .filter(key => menu.access[key])
        .map(v => `${v}_${menu.menuName}`);
    });
    const payload = {
      name: role.roleName,
      description: '',
      permission,
    };

    if (this.props.match.params.roleName) {
      rolesService.updateRoles(this.props.match.params.roleName, payload).subscribe(res => {
        this.handleClose();
      });
    } else {
      rolesService.saveRoles(payload).subscribe(res => {
        this.handleClose();
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { expanded, rows, menuList, roleList, accessTypes } = this.state;

    return (
      <div className={classes.root}>
        <Grid container spacing={7} className={classes.headerSection}>
          <Grid item xs={3}>
            <Typography variant="h6" gutterBottom>
              Access Rights: {this.props.match.params.roleName ? 'Edit' : 'Add'}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.actionBlock}>
            <Button variant="outlined" color="primary" style={{ marginRight: 20 }} onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={this.createAccessRole}>
              {this.props.match.params.roleName ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Grid>

        <Paper elevation="none" style={{ marginTop: 15 }}>
          <DndProvider backend={HTML5Backend}>
            <div style={{ padding: 20 }}>
              <Grid container spacing={7}>
                <Grid item xs={3}>
                  <Accordion
                    className={classes.accordianOuter}
                    expanded={expanded === 'panel1'}
                    onChange={() => this.handleAcordianChange('panel1')}
                    disabled>
                    <AccordionSummary
                      className={classes.AccordionSummary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Typography className={classes.heading}>Role</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accList}>
                      <SidemenuDraggable data={roleList} type="role" draggable={true} />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    className={classes.accordianOuter}
                    expanded={expanded === 'panel2'}
                    onChange={() => this.handleAcordianChange('panel2')}
                    disabled={rows.length === 0}>
                    <AccordionSummary
                      className={classes.AccordionSummary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header">
                      <Typography className={classes.heading}>Menu</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accList}>
                      <SidemenuDraggable data={menuList} type="menu" draggable={true} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={9}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="table drop area">
                      <TableHead>
                        <TableRow>
                          <TableCell>Role</TableCell>
                          <TableCell align="right">Menu Name</TableCell>
                          <TableCell align="right">Full</TableCell>
                          {accessTypes.map(acc => (
                            <TableCell key={acc.id} align="right">
                              {acc.name}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                    </Table>
                    <div style={{ overflow: 'auto', height: '650px' }}>
                      <Table className={classes.table} aria-label="simple table" style={{ tableLayout: 'fixed' }}>
                        <TableBody>
                          {rows.map((row, idx) => (
                            <TableRow key={`row-${idx}`}>
                              <TableCell component="th" scope="row">
                                {this.props.match.params.roleName && <span>{row.roleName}</span>}
                                {!this.props.match.params.roleName && idx < 1 && (
                                  <TextField
                                    id="roleName"
                                    value={row.roleName}
                                    placeholder="Type Role Name here..."
                                    onChange={this.handleRoleName}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="right" className="menu-name-text">
                                {row.menuName}
                              </TableCell>
                              <TableCell align="right">
                                {idx > 0 && (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={row.full || false}
                                        value={row.full || ''}
                                        onChange={e => this.toggleFullAccess(e, idx, row)}
                                        name="full"
                                        color="primary"
                                      />
                                    }
                                  />
                                )}
                              </TableCell>
                              {accessTypes.map(acc => (
                                <TableCell key={acc.id} align="right">
                                  {idx > 0 && this.isAcessExist(row, acc) && (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={row['access'][acc.name] || false}
                                          value={row['access'][acc.name] || ''}
                                          onChange={e => this.toggleAccess(e, idx, acc.name, row)}
                                          name={acc.name}
                                          color="primary"
                                        />
                                      }
                                    />
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                    <Table className={classes.table} aria-label="table drop area">
                      <TableBody>
                        <TableRow key="dropzone">
                          <TargetBox onDrop={this.onDrop}></TargetBox>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
          </DndProvider>
        </Paper>
      </div>
    );
  }
}
export default withRouter(withStyles(useStyles)(AccessRightsFormComponent));

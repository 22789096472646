import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { map } from 'rxjs/operators';
import { EndorsementService } from '../../remote-api/api/endorsement-services';
import { CategoryService } from '../../remote-api/api/master-services';
import { PlanService } from '../../remote-api/api/plan-services';
import { Eo2v2DataGrid } from '../../shared-components';
import { CloseOutlined } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import ProviderBlacklistModal from "./modals/provider.blackist.modal";
// import ProviderCategoryListModal from "./modals/provider.category.list.modal";
// import ProviderCategorizeModal from "./modals/provider.category.modal";
// import ProviderUnBlacklistModal from "./modals/provider.unblacklist.modal";
import DateFnsUtils from '@date-io/date-fns';
import { Button } from 'primereact/button';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ApprovalDailog } from '../../quotation-service-ui/src/components/quotation.list';
const useStyles = makeStyles(theme => ({
  tableBg: {
    height: 400,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  background: '#fff',
  // border: '2px solid #000',
  boxShadow: 24,
  padding: '2% 3%',
  borderRadius: '20px',
};
const endorsementservice = new EndorsementService();
const planservice = new PlanService();
const categoryservice = new CategoryService();

// let pls$ = planservice.getPlans();
// let ct$ = categoryservice.getCategories();

const columnsDefinations = [
  { field: 'policyCode$', headerName: 'Policy Number' },
  { field: 'id', headerName: 'Endorsement Number' },
  { field: 'memberAction', headerName: 'Endorsement Action' },
  { field: 'endorsementDateVal', headerName: 'Endorsement Date' },
  { field: 'totalPremium', headerName: 'Premium Amount' },
  { field: 'adjustPremiumAmt', headerName: 'Adjust Premium Amount' },
  { field: 'endorsementStatus', headerName: 'Status' },
];

export default function EndorsementListComponent(props) {
  const history = useHistory();
  const [rows, setRows] = React.useState(props.rows);
  const [planList, setPlanList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [selectionBlacklistMenuDisabled, setSelectionBlacklistMenuDisabled] = React.useState(true);
  const [selectionUnBlacklistMenuDisabled, setSelectionUnBlacklistMenuDisabled] = React.useState(true);
  const [generateInvoiceMenuDisabled, setGenerateInvoiceMenuDisabled] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [approvalOpen, setApprovalOpen] = React.useState({ value: null, isOpen: false });
  const [searchType, setSearchType] = React.useState();
  const [endorsementStartDate, setEndorsementStartDate] = React.useState();
  const [endorsementEndDate, setEndorsementEndDate] = React.useState();
  const [state, setState] = React.useState({
    action: '',
    openBlacklistModal: false,
    openUnBlacklistModal: false,
    openCategoryModal: false,
    openCategoryListModal: false,
    providerCategoryHistorys: [],
    providerIds: [],
    blackListedProviderids: [],
  });
  const [reloadTable, setReloadTable] = React.useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const theme = useTheme();

  const dataSource$ = (
    pageRequest = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
    },
    page = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
      endorsementStartDate: endorsementStartDate === '' ? 0 : endorsementStartDate - 5.5 * 60 * 60 * 1000,
      endorsementEndDate:
        endorsementEndDate === ''
          ? endorsementStartDate === ''
            ? 0
            : Number(endorsementStartDate) - 5.5 * 60 * 60 * 1000
          : !endorsementEndDate
          ? Number(endorsementStartDate) - 5.5 * 60 * 60 * 1000
          : Number(endorsementEndDate) - 5.5 * 60 * 60 * 1000,
    },
  ) => {
    pageRequest.sort = ['rowLastUpdatedDate dsc'];
    if (pageRequest.searchKey) {
      pageRequest['id'] = pageRequest.searchKey.trim();
      pageRequest['policyNo'] = pageRequest.searchKey.trim();
    }
    delete pageRequest.searchKey;

    return endorsementservice.getEndorsements(searchType === 1 ? page : pageRequest).pipe(
      map(data => {
        let content = data.content?.map(obj => {
          return {
            ...obj,
            policyCode$: obj?.policyEndorsements[0].policyCode,
          };
        });
        let records = content.map(item => {
          item['endorsementDateVal'] = new Date(item.endorsementDate).toLocaleDateString();
          return item;
        });

        data.content = records;
        return data;
      }),
    );
  };
  // const useObservable2 = (observable, setter) => {
  //   useEffect(() => {
  //     let subscription = observable.subscribe(result => {
  //       let tableArr = [];
  //       if (result.content && result.content.length > 0) {
  //         result.content.forEach(ele => {
  //           tableArr.push({
  //             name: ele.name,
  //             id: ele.id,
  //           });
  //         });
  //       }
  //       setter(tableArr);
  //     });
  //     return () => subscription.unsubscribe();
  //   }, [observable, setter]);
  // };

  // const useObservable = (observable, setter) => {
  //   useEffect(() => {
  //     let subscription = observable.subscribe(result => {
  //       setter(result.content);
  //     });
  //     return () => subscription.unsubscribe();
  //   }, [observable, setter]);
  // };

  // useObservable2(pls$, setPlanList);
  // useObservable(ct$, setCategoryList);

  const classes = useStyles();

  const handleOpen = () => {
    history.push('/endorsements?mode=create');
  };

  const closeBlacklistModal = () => {
    setState({
      ...state,
      openBlacklistModal: false,
    });
  };

  const closeUnBlacklistModal = () => {
    setState({
      ...state,
      openUnBlacklistModal: false,
    });
  };

  const closeCategorizeModal = () => {
    setState({
      ...state,
      openCategoryModal: false,
    });
  };

  const closeCategoryListModal = () => {
    setState({
      ...state,
      openCategoryListModal: false,
    });
  };

  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  const openEditSection = provider => {
    localStorage.setItem('endorsementId', provider.id);
    history.push(`/endorsements/${provider.id}?mode=edit`);
  };

  const handleSelectedRows = selectedEndorsements => {
    if (selectedEndorsements.length == 0) {
      setGenerateInvoiceMenuDisabled(true);
    } else {
      setGenerateInvoiceMenuDisabled(false);
    }

    //   if (selectedProviders.length == 0) {
    //     setSelectionBlacklistMenuDisabled(true);
    //     setSelectionUnBlacklistMenuDisabled(true);
    //   } else {
    //     let sp = [];
    //     let blp = [];
    //     let filteredLength = selectedProviders.filter(p => !p.blackListed).length;
    //     let blFilterdLength = selectedProviders.filter(p => p.blackListed).length;
    //     setSelectionBlacklistMenuDisabled(filteredLength != selectedProviders.length);
    //     setSelectionUnBlacklistMenuDisabled(blFilterdLength != selectedProviders.length);
    //     sp = selectedProviders.filter(p => !p.blackListed).map(ele => ele.id);
    //     blp = selectedProviders.filter(p => p.blackListed).map(ele => ele.id);
    //     setState({
    //       ...state,
    //       providerIds: sp,
    //       blackListedProviderids: blp,
    //     });
    //   }
  };

  const generateDate = () => {
    setOpen(true);
    setSearchType(1);
  };

  const generateInvoice = e => {};

  const openUnBlacklist = e => {
    setState({
      ...state,
      openUnBlacklistModal: true,
    });
  };

  const openCategorize = e => {
    setState({
      ...state,
      openCategoryModal: true,
    });
  };

  // const showCategoryList = val => {
  //   val.providerCategoryHistorys.forEach((value, i) => {
  //     value['startDate'] = new Date(value.startDate);
  //     value['id'] = i;
  //     planList.forEach(pln => {
  //       if (value.planId === pln.id) {
  //         value['planName'] = pln.name;
  //       }
  //     });
  //     categoryList.forEach(cat => {
  //       if (value.categoryId === cat.id) {
  //         value['catName'] = cat.name;
  //       }
  //     });
  //   });
  //   setState({
  //     ...state,
  //     openCategoryListModal: true,
  //     providerCategoryHistorys: val.providerCategoryHistorys,
  //   });
  // };
  const xlsColumns = ['id', 'endorsementDateVal', 'policyId', 'adjustPremiumAmt'];

  const configuration = {
    enableSelection: true,
    scrollHeight: '300px',
    pageSize: 10,
    actionButtons: [
      {
        key: 'edit_endorsement',
        icon: 'pi pi-user-edit',
        className: 'ui-button-warning',
        tooltip: 'Edit',
        onClick: openEditSection,
        // disabled: e => e.premiumCalculationStatus === 'COMPLETED',
      },
      {
        key: 'update_endorsement',
        icon: 'pi pi-check',
        className: 'ui-button-warning',
        tooltip: 'Approve',
        onClick: e => handleCheckMarkClick(e),
        disabled: e =>
          !(e.memberUploadStatus === 'COMPLETED' && e.premiumCalculationStatus === 'COMPLETED') ||
          e.endorsementStatus === 'APPROVED',
      },
    ],
    header: {
      enable: true,
      enableDownload: true,
      downloadbleColumns: xlsColumns,
      addCreateButton: true,
      onCreateButtonClick: handleOpen,
      text: 'Endorsement Management',
      enableGlobalSearch: true,
      searchText: 'Search by Endorsement number or Policy No.',
      onSelectionChange: handleSelectedRows,
      selectionMenus: [{ icon: '', text: 'Endorsement Date', onClick: generateDate }],
      selectionMenuButtonText: 'Action',
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onSearch = () => {
    setOpen(false);
    setReloadTable(true);
    setTimeout(() => {
      setReloadTable(false);
      setEndorsementStartDate(null);
      setEndorsementEndDate(null);
    }, [1000]);
  };

  const handleCheckMarkClick = e => {
    setApprovalOpen({
      value: e,
      isOpen: true,
    });
  };

  const handleCloseApproveDialog = () => {
    setApprovalOpen({
      value: null,
      isOpen: false,
    });
  };

  const handleApproveAction = () => {
    const pageRequest = {
      action: 'approve',
    };
    endorsementservice.approveEndorsement(approvalOpen?.value?.id, pageRequest).subscribe(res => {
      setSuccess(prev => !prev);
      setReloadTable(true);

      setTimeout(() => {
        setSuccess(false);
        setReloadTable(false);
      }, 3000);
      handleCloseApproveDialog();
    });
  };
  return (
    <div>
      <Eo2v2DataGrid
        $dataSource={dataSource$}
        columnsDefination={columnsDefinations}
        onEdit={openEditSection}
        config={configuration}
        reloadTable={reloadTable}
      />

      {isSuccess && (
        <Snackbar open={isSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert variant="filled" severity="success">
            Endorsement Approved
          </Alert>
        </Snackbar>
      )}

      <ApprovalDailog
        open={approvalOpen?.isOpen}
        handleClose={handleCloseApproveDialog}
        No={approvalOpen?.value?.id}
        Tag={''}
        handleApproveAction={handleApproveAction}
        questionAsk={'Are you sure to approve the endorsement'}
        type={'Endorsement'}
      />

      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box style={modalStyle}>
          <Box>
            <Box id="alert-dialog-slide-description">
              {searchType == 1 && (
                <>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box component="h3" marginBottom={'10px'}>
                      Endorsement Date
                    </Box>
                    <CloseOutlined onClick={() => setOpen(false)} style={{ cursor: 'pointer' }} />
                  </Box>
                  <Box display={'flex'} marginBottom={'10px'}>
                    <Box display={'flex'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        From
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={endorsementStartDate}
                            onChange={date => setEndorsementStartDate(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                    <Box display={'flex'} marginLeft={'3%'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        To
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={endorsementEndDate}
                            onChange={date => setEndorsementEndDate(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box marginTop={'10%'}>
            <Button
              variant="contained"
              style={{ backgroundColor: theme.palette.primary.main, color: '#fff' }}
              onClick={onSearch}>
              Search
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* {searchType == 1 && (
        <>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box component="h3" marginBottom={'10px'}>
              Endorsement Start Date
            </Box>
            <CloseOutlined onClick={() => setOpen(false)} style={{ cursor: 'pointer' }} />
          </Box>
          <Box display={'flex'} marginBottom={'10px'}>
            <Box display={'flex'}>
              <Typography 
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '700',
                  textTransform: 'capitalize',
                }}>
                From
              </Typography>
              &nbsp;
              <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
              <Box style={{ marginBottom: '10px' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    views={['year', 'month', 'date']}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    autoOk={true}
                    id="date-picker-inline"
                    value={endorsementStartDate}
                    onChange={date => setEndorsementStartDate(date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change ing date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
            <Box display={'flex'} marginLeft={'3%'}>
              <Typography
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '700',
                  textTransform: 'capitalize',
                }}>
                To
              </Typography>
              &nbsp;
              <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
              <Box style={{ marginBottom: '10px' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    views={['year', 'month', 'date']}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    autoOk={true}
                    id="date-picker-inline"
                    value={endorsementEndDate}
                    onChange={date => setEndorsementEndDate(date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change ing date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
          </Box>
        </>
      )} */}
      {/* <DataGrid rows={rows} columns={props.columns} pageSize={10} multiselect/> */}
      {/* {state.openBlacklistModal ? (
        <ProviderBlacklistModal
          closeBlacklistModal={closeBlacklistModal}
          openBlacklistModal={state.openBlacklistModal}
          handleBlacklistSubmit={handleBlacklistSubmit}
        />
      ) : null}
      {state.openUnBlacklistModal ? (
        <ProviderUnBlacklistModal
          closeUnBlacklistModal={closeUnBlacklistModal}
          openUnBlacklistModal={state.openUnBlacklistModal}
          handleUnBlacklistSubmit={handleUnBlacklistSubmit}
        />
      ) : null}
      {state.openCategoryModal ? (
        <ProviderCategorizeModal
          closeCategorizeModal={closeCategorizeModal}
          openCategoryModal={state.openCategoryModal}
          handleCategorizeSubmit={handleCategorizeSubmit}
          planList={planList}
          categoryList={categoryList}
        />
      ) : null}

      {state.openCategoryListModal ? (
        <ProviderCategoryListModal
          openCategoryListModal={state.openCategoryListModal}
          closeCategoryListModal={closeCategoryListModal}
          planList={planList}
          categoryList={categoryList}
          providerCategoryHistorys={state.providerCategoryHistorys}
        />
      ) : null} */}
    </div>
  );
}

import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
    header: {
        // border: '1px solid rgba(0, 0, 0, 0.1)',
        // borderRadius: '10px 10px 0px 0px',
        // background: '#0edb8a',
        padding: 20,
        borderBottom: 'none',
        backgroundColor: 'rgb(241, 241, 241)',
        color: ' rgb(161, 161, 161)',
        borderRadius: ' 8px 8px 0px 0px',
    },
    customStyle: {
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '0px 0px 10px 10px',
        background: '#ffffff',
        padding: '40px 40px',
        borderTop: 'none'
    },
    pictureContainer: {
        width: 200,
        height: 180,
        border: '1px solid #002776',
        borderRadius:"10px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
       
    },
    svgImg: {
        fontSize:"10rem",
        color:" #818181"
    },
    headerText: {
        fontSize: '18px',
        fontWeight: 'Bold',
        color: '#002776'
    },
    subheader: {
        fontSize: '16px',
        fontWeight: 'Bold'
    },
    body: {
        fontSize: '16px',
        fontWeight: 'Bold'
    },
    dropdownsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    formControl: {
        minWidth: 182,
    },
    detailsText: {
        fontSize: '16px',
    },
    button: {
        fontSize: '12px',
        textAlign: 'center',
        width: '100%',
    },
    dropdown: {
        marginLeft: theme.spacing(2),
        '&:first-child': {
            marginLeft: 0,
        },

    },
}));


const Profile = () => {
    const classes = useStyles();
    const history = useHistory();
    // const [userDetails, setUserDetails] = useState({ () => (JSON.parse(localStorage.getItem('user_details')))})
    const { keycloak } = useKeycloak();
    const user_details = JSON.parse(localStorage.getItem('user_details'));
    const user_Type =  localStorage.getItem('userType');


    return (
        <div>
            <Box>
                <Box className={classes.header}>
                    <Typography className={classes.headerText}>Profile Details</Typography>
                </Box>
                <Box className={classes.customStyle}>
                    <Grid container>

                        <Grid item xs={12} sm={2} container justifyContent='left' alignItems='center'>
                            <Box className={classes.pictureContainer}>
                                {/*  image can go here */}
                                {/* <img src='#' alt='img'/> */}
                                <PersonIcon  className={classes.svgImg} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10} container  alignItems='center'>
                            <Grid item xs={12}>
                                <Typography className={classes.detailsText}><b>User Type:</b> {user_Type}</Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography className={classes.detailsText}><b>Name:</b> {user_details?.name}</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography className={classes.detailsText}><b>Email:</b> {user_details?.email}</Typography>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Typography className={classes.detailsText}><b>Email:</b> {user_details.email_verified}</Typography>
                            </Grid> */}

                        </Grid>
                    </Grid>
                </Box>

            </Box>
        </div>
    )
}

export default Profile
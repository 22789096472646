import { Breadcrumbs, lighten, Link, Snackbar } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { map } from 'rxjs/operators';
import { BenefitStructureService } from '../../../remote-api/api/benefit-structure-services';
import { BenefitService } from '../../../remote-api/api/master-services';
import { ProductService } from '../../../remote-api/api/product-services';

import { CloseOutlined } from '@material-ui/icons';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';

import { defaultPageRequest } from '../../../remote-api/models/page.request';

import { EO2V2Autocomplete } from '../../../shared-components';
import {
  clearSelectedRuleFromBenefitInNavPath,
  deleteRule,
  extractRulesFromBenefitStructures,
  getSelectedRuleId,
  hasAnyRuleInBenefitHierarchies,
  setRulesInBenefitStructures,
} from '../../util/product-util';

import BenifitDesignRuleTable from './benifit-design-rule-table';
import RuleDesignModal from './rule-design-modal';
import RuleDesignPreviewModal from './rule-design-preview-modal';
import Asterisk from '../../../shared-components/components/red-asterisk';
import { el } from 'date-fns/locale';

const benefitStructureService = new BenefitStructureService();
const benefitService = new BenefitService();
const productservice = new ProductService();

const useStyles = makeStyles(theme => ({
  handleSubBenefitsScroll: {
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  benifitDesignRoot: {
    flexGrow: 1,
    minHeight: 100,
    padding: 30,
  },
  header: {
    paddingTop: 10,
    paddingBottom: 10,
    color: '#4472C4',
  },
  mainBenifitAction: {
    borderRadius: 14,
    backgroundColor: lighten(theme.palette.primary.light, 0.9) /* "#aceae8" */,
    '&.MuiGrid-item': {
      paddingTop: '20px',
      paddingBottom: '20px',
    },
  },
  mainBenifitActionLbl: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainBenifitBtnSection: {
    display: 'flex',
    /* justifyContent: "space-evenly", */
  },
  subBenifitsSection: {
    marginTop: 10,
  },
  subBenifitLabel: {
    textAlign: 'center',
    fontSize: 14,
  },
  subBenifitsMenuList: {
    backgroundColor: lighten(theme.palette.primary.light, 0.9) /* "#aceae8" */,
    height: 300,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 14,
  },
  subBenifitTabs: {
    flex: 1,
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  subBenifitTab: {
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  },
  subBenifitsMenu: {
    fontSize: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 182,
  },
}));

function a11yProps(index, prefix) {
  return {
    id: `main-benifit-tab-${index}-${prefix}`,
    'aria-controls': `main-benifit-tabpanel-${index}-${prefix}`,
  };
}

export default function BenifitDesignComponent(props) {
  const classes = useStyles();
  const [benefitStructures, setBenefitStructures] = React.useState([]);
  const [selectedBenefitStructure, setSelectedBenefitStructure] = useState();
  const [selectedBenefitStructureIndex, setSelectedBenefitStructureIndex] = React.useState(0);
  const [selectedBenefitIndex, setSelectedBenefitIndex] = React.useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [benefitList, setBenefitList] = useState([]);
  const [isOpenRuleModal, setIsOpenRuleModal] = useState(false);
  const [isOpenRulePreviewModal, setIsOpenRulePreviewModal] = useState(false);
  const selectedBenefitStrucute = useRef(null);
  const navPath = useRef(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [editFormValues, setEditFormValues] = React.useState(undefined);
  const [indexOfEditRule, setIndexOfEditRule] = React.useState();
  const [payload, setPayload] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [tabHeads, setTabHeads] = useState([]);
  const [allBenefitStructures, setAllBenefitStructures] = React.useState([]);
  const [benefitData, setBenefitData] = React.useState([]);
  const [parentBenefit, setParentBenefit] = React.useState([]);
  const [parentToChildValues, setParentTOChildValues] = useState(null);
  const history = useHistory();

  const onSetRootBenefit = (rootBenefit, index = 0, opBenefitStructures = []) => {
    if (navPath.current) {
      clearSelectedRuleFromBenefitInNavPath(navPath.current);
    }
    navPath.current = [rootBenefit];
    selectedBenefitStrucute.current = benefitStructures[index] || opBenefitStructures[index];
    rootBenefit.ruleList = rootBenefit.ruleList || [];

    if (!rootBenefit.parameters) {
      benefitService
        .getBenefitParameterDetails2(rootBenefit?.code, selectedBenefitStrucute?.current?.id)
        .subscribe(response => {
          rootBenefit.parameters = response.parameters;
          rootBenefit.benefitStructureId = response.benefitStructureId;

          setBenefitList(prev => {
            rootBenefit.child = [
              ...rootBenefit?.child?.filter(ele => ele.ruleList.length > 0),
              ...rootBenefit?.child?.filter(ele => ele.ruleList.length === 0),
            ];
            return [rootBenefit];
          });

          setSelectedBenefitStructureIndex(index);
          setSelectedBenefitIndex(0);
          setSelectedBenefitStructure(rootBenefit.name);
        });
    } else {
      setBenefitList([rootBenefit]);
      setSelectedBenefitStructureIndex(index);
      setSelectedBenefitIndex(0);
      setSelectedBenefitStructure(rootBenefit.name);
    }
  };

  const saveRule = () => {
    // const rules = extractRulesFromBenefitStructures(benefitList);
    const productId = localStorage.getItem('productId');
    // let temp = [...payload];
    // if (rules.length) {
    //   rules.map(el => {
    //     let p = temp.find(item => item.internalId === el.internalId);
    //     if (!p) {
    //       temp.push(el);
    //     }
    //   });
    //   setPayload(temp);
    // }
    // console.log
    productservice.saveProductRules(productId, payload).subscribe(() => {
      setOpenSnackbar(true);
      //setPayload([]);
      window.location.href = `/products/${productId}?mode=edit&step=1`;
      //  history.push(`/products/${productId}?mode=edit&step=1`);
    });
  };

  const handleBenefitTabChange = (event, index) => {
    const benefit = benefitList[index];
    navPath.current[navPath.current.length - 1] = benefit;
    benefit.ruleList = benefit.ruleList || [];

    if (!benefit.parameters) {
      benefitService.getBenefitParameterDetails2(benefit.code, selectedBenefitStrucute.current.id).subscribe(response => {
        benefit.parameters = response.parameters;
        benefit.benefitStructureId = response.benefitStructureId;
        setSelectedBenefitIndex(index);
      });
    } else {
      setSelectedBenefitIndex(index);
    }
  };

  const getData = () => {
    // if (props.productDetails?.productRules?.length) {
    //   onSetRootBenefit(benefitStructures[0].hirearchy, 0, benefitStructures);
    //   const temp = [];
    //   const uniqueIds = new Set();
    //   console.log('props.productDetails.productRules', props.productDetails.productRules);
    //   props.productDetails.productRules.forEach(ele => {
    //     benefitStructures.forEach(item => {
    //       if (ele?.benefitId === item?.hirearchy?.id && !uniqueIds.has(item.hirearchy.id)) {
    //         temp.push({ id: item.hirearchy.id, name: item.description });
    //         uniqueIds.add(item.hirearchy.id);
    //       }
    //     });
    //   });
    //   setTabHeads(temp);
    // }
    let bts$ = benefitStructureService.getAllBenefitStructures({
      page: 0,
      size: 10,
      summary: true,
      active: true,
    });

    bts$.subscribe(page => {
      if (props.productDetails?.productRules?.length) {
        const temp = [];
        const uniqueIds = new Set();

        props.productDetails.productRules.forEach(ele => {
          page.content.forEach(item => {
            if (ele?.benefitId === item?.hirearchy?.id && !uniqueIds.has(item.hirearchy.id)) {
              temp.push({ id: item.hirearchy.id, name: item.description });
              uniqueIds.add(item.hirearchy.id);
            }
          });
        });

        setTabHeads(temp);

        const desiredResult = page.content?.filter(ele => {
          let value = temp?.findIndex(res => {
            return res?.id === ele?.benefitId;
          });
          return value != -1;
        });

        setTimeout(() => {
          sessionStorage.setItem('PreView_Save_Benefit_Design', JSON.stringify([...desiredResult]));
        }, 500);
      }

      setRulesInBenefitStructures(page.content, props?.productDetails.productRules || []);
      setBenefitStructures(page.content);
      onSetRootBenefit(page.content[0].hirearchy, 0, page.content);
      setParentTOChildValues({ ...page });
    });
  };

  useEffect(() => {
    getData();
  }, [props?.productDetails]);

  const productDataSourceCallback$ = (
    params = {},
    action = '',
    pageRequest = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
    },
  ) => {
    let reqParam = { ...pageRequest, ...params };
    reqParam.size = 10;
    if (action.searchText && action.searchText.length > 2) {
      reqParam = {
        ...reqParam,
        code: action.searchText,
        description: action.searchText,
      };
      return benefitStructureService.getAllBenefitStructures(reqParam).pipe(
        map(page => {
          setRulesInBenefitStructures(page.content, props?.productDetails?.productRules || []);
          setBenefitStructures(page.content);
          return page;
        }),
      );
    } else {
      return benefitStructureService
        .getAllBenefitStructures({
          page: 0,
          size: 10,
          summary: true,
          active: true,
        })
        .pipe(
          map(page => {
            setRulesInBenefitStructures(page.content, props?.productDetails?.productRules || []);
            setBenefitStructures(page.content);
            onSetRootBenefit(page.content[0].hirearchy, 0, page?.content);
            if (props.productDetails?.productRules?.length) {
              const temp = [];
              const uniqueIds = new Set();

              props.productDetails.productRules.forEach(ele => {
                page.content.forEach(item => {
                  if (ele?.benefitId === item?.hirearchy?.id && !uniqueIds.has(item.hirearchy.id)) {
                    temp.push({ id: item.hirearchy.id, name: item.description });
                    uniqueIds.add(item.hirearchy.id);
                  }
                });
              });

              setTabHeads(temp);
            }
            return page;
          }),
        );
    }
  };

  const handlePChange = (e, value) => {
    setBenefitData(value);

    if (value) {
      const newObj = { id: value?.hirearchy?.id, name: value?.description };
      if (!tabHeads.some(item => item.id === newObj.id)) {
        setTabHeads(prev => [...prev, newObj]);
        setSelectedTabIndex(tabHeads.length);
      } else {
        setSelectedTabIndex(tabHeads.findIndex(item => item.id === newObj.id));
      }

      setSelectedBenefitStructure(value);

      let index = -1;

      benefitStructures.forEach((ele, idx) => {
        if (value?.benefitId === ele?.benefitId) index = idx;
      });

      setSelectedBenefitStructureIndex(index);
      onSetRootBenefit(benefitStructures[index].hirearchy, index);
    } else {
      // setSelectedBenefitStructure(selectedBenefitStructure);
    }
  };

  const onSelectBenefitFromNavPath = benefit => {
    const index = navPath.current.indexOf(benefit);

    if (index == 0) {
      onSetRootBenefit(benefit, selectedBenefitStructureIndex);
    } else {
      clearSelectedRuleFromBenefitInNavPath(navPath.current, index);
      const parentBenefit = navPath.current[index - 1];
      navPath.current = navPath.current.slice(0, index + 1);
      setBenefitList(parentBenefit.child);
      setSelectedBenefitIndex(parentBenefit.child.indexOf(benefit));
    }
  };

  // const buildBreadcrumb = () => {
  //   // if (navPath.current && navPath.current.length > 0) {
  //   if (tabHeads && tabHeads?.length > 0) {
  //     return (
  //       <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} maxItems={3} aria-label="breadcrumb">
  //         {navPath.current.map((path, index) => {
  //           if (index === navPath.current.length - 1) return <Typography color="primary">{path.name}</Typography>;
  //           else {
  //             return (
  //               <Link
  //                 underline="hover"
  //                 color="inherit"
  //                 href="#"
  //                 style={{ textDecoration: 'underline' }}
  //                 onClick={e => {
  //                   e.stopPropagation();
  //                   onSelectBenefitFromNavPath(path);
  //                 }}>
  //                 {path.name}
  //               </Link>
  //             );
  //           }
  //         })}
  //         ;
  //       </Breadcrumbs>
  //     );
  //   } else return null;
  // };

  const buildBreadcrumb = () => (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} maxItems={3} aria-label="breadcrumb">
      {navPath?.current?.map((path, index) => (
        <Link
          key={index}
          underline="hover"
          color={index === navPath.current.length - 1 ? 'primary' : 'inherit'}
          onClick={e => {
            e.preventDefault();
            if (index !== navPath.current.length - 1) onSelectBenefitFromNavPath(path);
          }}>
          {path.name}
        </Link>
      ))}
    </Breadcrumbs>
  );

  const onRuleAdd = data => {
    const finalData = { ...data };
    // const finalData = { ...data };
    benefitList[selectedBenefitIndex].ruleList = [...benefitList[selectedBenefitIndex].ruleList, finalData];

    if (navPath.current && navPath.current.length > 1) {
      finalData.parentInternalId = getSelectedRuleId(navPath.current[navPath.current.length - 2]);
    }

    const rules = extractRulesFromBenefitStructures(benefitList);
    let temp = [...payload];

    if (rules.length) {
      rules.map(el => {
        let p = temp.find(item => item.internalId === el.internalId);
        if (!p) {
          temp.push(el);
        }
      });
      setPayload(temp);
    }
    setIsOpenRuleModal(false);
  };

  const onRuleEditSave = editedData => {
    const copiedBenefitList = [...benefitList];
    // console.log(benefitList)
    const selectedBenefit = copiedBenefitList[selectedBenefitIndex];

    const newRuleList = [...selectedBenefit.ruleList];

    const updatedRuleDetails = { ...newRuleList[indexOfEditRule], ...editedData };
    newRuleList[indexOfEditRule] = updatedRuleDetails;

    const updatedBenefit = { ...selectedBenefit, ruleList: newRuleList };
    copiedBenefitList[selectedBenefitIndex] = updatedBenefit;
    setBenefitList(copiedBenefitList);

    const rules = extractRulesFromBenefitStructures(copiedBenefitList);
    let temp = [...payload];
    if (rules.length) {
      rules.map(el => {
        let p = temp.find(item => item.internalId === el.internalId);
        if (!p) {
          temp.push(el);
        }
      });
      setPayload(temp);
    }

    setEditFormValues();
    setIndexOfEditRule();
    setIsOpenRuleModal(false);
  };

  const onRuleDelete = (row, idx) => {
    // const ruleList=[...benefitList[selectedBenefitIndex].ruleList];
    // ruleList.splice(ruleList.indexOf(row),1);
    // benefitList[selectedBenefitIndex].ruleList= ruleList;
    // setBenefitList([...benefitList]);

    deleteRule(benefitList[selectedBenefitIndex], row);
    setBenefitList([...benefitList]);
  };

  const onRuleEdit = (row, idx) => {
    if (row.coverageExpression.includes('%')) {
      row.coverage = 'coverage with %';
    }
    setEditFormValues(row);
    setIndexOfEditRule(idx);
    setIsOpenRuleModal(true);
  };

  const onRuleSelect = rule => {
    setParentBenefit(rule);
    const benefit = benefitList[selectedBenefitIndex];

    if (benefit.child) {
      rule.isSelected = true;
      const tobeBenefit = benefit.child[0];
      tobeBenefit.ruleList = tobeBenefit.ruleList || [];
      navPath.current = [...navPath.current, tobeBenefit];

      if (tobeBenefit.parameters) {
        setBenefitList(() => {
          return benefit.child;
        });
        setSelectedBenefitIndex(0);
      } else {
        // benefitService
        //   .getBenefitParameterDetails2(tobeBenefit.code, selectedBenefitStrucute.current.id)
        //   .subscribe(response => {
        //     tobeBenefit.parameters = response.parameters;

        //     tobeBenefit.benefitStructureId = response.benefitStructureId;
        //   });

        setBenefitList(() => {
          return benefit.child;
        });
        setSelectedBenefitIndex(0);
      }
    }
  };

  const getRuleListForRuleViewTable = () => {
    if (!navPath.current) {
      return [];
    }

    if (navPath.current.length) {
      const result = benefitList[selectedBenefitIndex].ruleList;
      return result;
    }

    const parentBenefit = navPath.current[navPath.current.length - 2];
    const selectedRuleId = getSelectedRuleId(parentBenefit);

    const result = benefitList[selectedBenefitIndex]?.ruleList.filter(r => r.parentInternalId === selectedRuleId);

    return result;
  };

  const handleBenefitStructureTabChange = index => {
    let rootBenefit = benefitStructures.find(ele => tabHeads[index]?.id === ele?.hirearchy?.id);

    //PreView_Save_Benefit_Design
    let value = sessionStorage.getItem('PreView_Save_Benefit_Design');
    if (!value) {
      sessionStorage.setItem('PreView_Save_Benefit_Design', JSON.stringify([{ ...rootBenefit }]));
    } else {
      const result = JSON.parse(value);
      sessionStorage.setItem(
        'PreView_Save_Benefit_Design',
        JSON.stringify(
          result?.findIndex(ele => ele.benefitId === rootBenefit?.benefitId) < 0
            ? [...result, { ...rootBenefit }]
            : [...result],
        ),
      );
    }

    onSetRootBenefit(rootBenefit?.hirearchy, index);
    setSelectedTabIndex(index);
    setSelectedBenefitIndex(0);
  };

  const onTabClose = index => {
    const tabHeadsClone = [...tabHeads];
    setTabHeads(tabHeadsClone.filter((tab, i) => i !== index));

    if (selectedTabIndex === index) {
      const hasLength = tabHeadsClone.length;
      const negative = -1;
      const previousIndex = index - 1;
      const tabIdx = previousIndex !== negative ? previousIndex : hasLength ? 0 : null;
      tabIdx && handleBenefitStructureTabChange(tabIdx);
    } else {
    }
  };

  const recursive = (nums, step, sum) => {
    if (nums == null || nums.length == 0) return sum;
    for (let i = 0; i < nums.length; i++) sum = recursive(nums[i].child, step + 1, sum) + nums[i]?.ruleList?.length;
    return sum;
  };

  const handleSubBenefitCount = benefitList => {
    return recursive(benefitList[selectedTabIndex]?.child, 0, 0);
  };

  const handleRestForm = () => {
    setEditFormValues(undefined);
  };
  return (
    <div style={{ padding: '5px' }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" variant="filled">
          Product updated successfully
        </Alert>
      </Snackbar>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>Benefit Design</Typography>
        </Grid>

        {Object.keys(props.productDetails)?.length > 0 && (
          <Grid item container xs={12} spacing={1}>
            {/* <Grid item><h4>Main Benifits</h4></Grid> */}

            <Grid item style={{ height: '350px' }}>
              <FormControl className={classes.formControl}>
                <EO2V2Autocomplete
                  id="benefitId"
                  name="benefitId"
                  label={
                    <span>
                      Benefit Name <Asterisk />
                    </span>
                  }
                  displayKey="description"
                  $dataSource={props.productDetails && productDataSourceCallback$}
                  value={benefitData}
                  changeDetect={true}
                  onChange={handlePChange}
                  parentToChildValues={parentToChildValues}
                  // required
                />
              </FormControl>
              {/* Benefit Name Drop Down*/}
              {tabHeads?.length ? (
                <Tabs
                  style={{ height: '400px' }}
                  indicatorColor="primary"
                  orientation="vertical"
                  variant="scrollable"
                  aria-label="Vertical tabs example"
                  className={`${classes.subBenifitTabs} ${classes.handleSubBenefitsScroll}`}
                  value={selectedBenefitIndex}
                  // value={0}
                  onChange={handleBenefitTabChange}>
                  {benefitList &&
                    benefitList?.map((subBenifit, idx) => {
                      return (
                        <Tab
                          key={idx}
                          textColor="inherit"
                          label={subBenifit?.name}
                          {...a11yProps(idx, 'benefit')}
                          style={{ color: subBenifit?.ruleList?.length > 0 ? '#3a7cff' : '' }}
                          className={`${classes.subBenifitTab}`}
                        />
                      );
                    })}
                </Tabs>
              ) : null}
            </Grid>

            {/* Below  section containing tables related to the data*/}
            <Grid item style={{ flex: 1, height: '350px' }}>
              <TabView
                scrollable
                activeIndex={selectedTabIndex}
                onTabChange={e => handleBenefitStructureTabChange(e?.index)}
                key={'tab_item'}>
                {tabHeads?.map((item, index) => {
                  return (
                    <TabPanel
                      key={index}
                      headerClassName="flex align-items-center"
                      headerTemplate={options => {
                        return (
                          <div
                            className="flex align-items-center gap-2 p-2"
                            style={{ cursor: 'pointer' }}
                            onClick={options.onClick}>
                            <p style={{ fontSize: '12px' }} className=" white-space-nowrap">
                              {item.name}
                            </p>
                            <CloseOutlined style={{ fontSize: '14px' }} onClick={() => onTabClose(index)} />
                          </div>
                        );
                      }}>
                      <Grid item xs={12} container spacing={4} justifyContent="flex-end" style={{ padding: '16px' }}>
                        <Grid item xs={12}>
                          {buildBreadcrumb()}
                        </Grid>

                        {/* Preview */}
                        <Grid item>
                          <Button variant="outlined" style={{ float: 'right' }} className="p-button-outlined">
                            Preview
                          </Button>
                          <RuleDesignPreviewModal
                            openDialog={isOpenRulePreviewModal}
                            handleClose={() => setIsOpenRulePreviewModal(false)}
                            previewHierarchyStructure={{}}></RuleDesignPreviewModal>
                        </Grid>

                        {/* Add Rules */}
                        <Grid item>
                          <Button
                            variant="outlined"
                            style={{ float: 'right' }}
                            className="p-button-outlined"
                            onClick={() => {
                              setIsOpenRuleModal(true);
                            }}>
                            Add Rule
                          </Button>

                          <RuleDesignModal
                            openDialog={isOpenRuleModal}
                            setOpenDialog={setIsOpenRuleModal}
                            forBenefit={benefitList[selectedBenefitIndex]}
                            key={isOpenRuleModal}
                            benefitNav={navPath?.current || []}
                            onAdd={onRuleAdd}
                            editFormValues={editFormValues}
                            onRuleEditSave={onRuleEditSave}
                            parentBenefit={parentBenefit}
                            handleRestForm={handleRestForm}
                          />
                        </Grid>
                      </Grid>

                      {benefitList && benefitList[selectedBenefitIndex] && (
                        <BenifitDesignRuleTable
                          ruleList={getRuleListForRuleViewTable()}
                          onRequestForChildRule={onRuleSelect}
                          hasChild={
                            benefitList[selectedBenefitIndex]?.child?.length > 0 && benefitList[selectedBenefitIndex]?.child
                          }
                          onRuleDelete={onRuleDelete}
                          onRuleEdit={onRuleEdit}
                          subBenefitCount={handleSubBenefitCount(benefitList)}
                        />
                      )}
                    </TabPanel>
                  );
                })}
              </TabView>
            </Grid>

            <Grid item container xs={12} spacing={1} style={{ marginTop: '-10px' }}>
              <Grid item xs={12}>
                <Button
                  className="p-button-outlined"
                  variant="outlined"
                  style={{ float: 'right', marginTop: '16px', marginBottom: '16px' }}
                  disabled={!hasAnyRuleInBenefitHierarchies(benefitList)}
                  onClick={saveRule}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
